@keyframes skeleton-loading {
    to {
        background-position: left;
    }
}

.skeleton {
    content: ' ';
    cursor: progress;
    border-radius: 2px;
    background: linear-gradient(90deg, #f4f4f4 40%, #fff, #f4f4f4 60%) right / 300% 100%;
    animation: skeleton-loading 1.5s linear infinite;
}