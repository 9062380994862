.right-panel {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 400px;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
}

.right-panel.open {
    right: 0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
}

.overlay.show {
    display: block;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    width: 100%;
    min-width: 600px;
}

/* Tablets (pantallas entre 768px y 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
    .right-panel {
        width: 60%;
    }

    .table {
        min-width: 500px;
    }
}

/* Celulares (pantallas menores a 768px) */
@media (max-width: 768px) {
    .right-panel {
        width: 90%;
    }

    .table {
        min-width: 300px;
    }

    .list-unstyled {
        font-size: 14px !important;
    }

    .select-intiza {
        height: 28px !important;
    }

    th {
        font-size: 12px !important;
    }

    td {
        font-size: 11px !important;
    }

    tr {
        padding: 0;
    }

    .subfooter {
        font-size: 12px;
        margin-top: 0px !important;
    }
}